<template>
  <div >
    <v-text-field
      outlined
      dense
      @input="onChange"
      v-model="editedItem.name"
      label="Nombre de producto"
      v-uppercase
      ref="nombre"
      v-on:keyup.enter="$emit('nextEvent')"
    ></v-text-field>
    <v-card
      class="mx-auto element"
      max-width="600"
      tile
      v-show="isOpen"
    >
      <v-list-item v-for="(result, i) in results" :key="i" @click="setResult(result)">
        <v-list-item-content >
          <v-list-item-title>{{ result.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Autocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    },
    editedItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      results: [],
      isLoading: false,
      arrowCounter: 0
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    items (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    },
    nextName () {
      this.$emit('nextFocus', 'nombreNuevo')
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.editedItem.name);

      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
    },

    filterResults() {
      this.results = this.items.filter(item => {
        return item.name.toLowerCase().indexOf(this.editedItem.name.toLowerCase()) > -1;
      });
    },
    setResult(result) {
      this.editedItem.name = result.name;
      this.isOpen = false;
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.editedItem.name = this.results[this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    }
  },
}
</script>

<style scoped>
  .element {
    max-height: 304px;
    min-width: 464px;
    margin-top: -26px;
    margin-right: 50px;
    transform-origin: left top;
    left: 220px;
    z-index: 100;
    position: absolute;
    overflow-y: auto;
  }
</style>
