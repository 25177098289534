<template>
    <v-container fluid>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            PRODUCTOS
          </h3>
        </v-card-title>
      </v-card>
      <br>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="success" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            clear-icon="mdi-delete"
            label="Buscar"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>

        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="green"
          @click="getReportInventario(user[0].area.entity.id)"
        >
          <v-icon
            dark
          >
            mdi-printer-settings
          </v-icon>
        </v-btn>

        <v-col
           cols="12"
           sm="12"
           xs="12"
           md="2"
           class="text-right"
        >
          <v-dialog
            v-model="dialog"
            scrollable
            persistent
            max-width="800px"
          >
           <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                color="primary"
                v-bind="attrs"
                v-on="on"
                large
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                NUEVO PRODUCTO
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="success"
                dark
                elevation="0"
              >{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container><br>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="editedItem.barcode"
                        label="Código de barras"
                        v-on:keyup.enter="focusName()"
                        :error-messages="errors.get('barcode')"
                        @keydown="errors.clear('barcode')"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="8"
                    >
                      <Autocomplete
                        :items="productNames"
                        :editedItem="editedItem"
                        ref="nombre_nuevo"
                        @nextEvent="focusStockMaximo"
                       />
                    </v-col>
                  </v-row>
                  <v-row>
                    <SelectLab ref="nombreLaboratorio" :editedItem="editedItem" :laboratories="laboratories"/>
                    <v-btn fab dark small color="success" @click="dialogLaboratory = !dialogLaboratory"><v-icon dark>mdi-plus</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <SelectGeneric :editedItem="editedItem" :generics="generics"/>
                    <v-btn fab dark small color="success" @click="dialogGeneric = !dialogGeneric"><v-icon dark>mdi-plus</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <SelectCategory :editedItem="editedItem" :categories="categories"/>
                    <v-btn fab dark small color="success" @click="dialogCategory = !dialogCategory"><v-icon dark>mdi-plus</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <SelectPresentation :editedItem="editedItem" :presentations="presentations"/>
                    <v-btn fab dark small color="success" @click="dialogPresentation = !dialogPresentation"><v-icon dark>mdi-plus</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <SelectLocation :editedItem="editedItem" :locations="locations"/>
                    <v-btn fab dark small color="success" @click="dialogLocation = !dialogLocation"><v-icon dark>mdi-plus</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.maximum_stock"
                        label="Stock Máximo"
                        outlined
                        dense
                        ref="aximum_stock"
                        :error-messages="errors.get('maximum_stock')"
                        @keydown="errors.clear('maximum_stock')"
                        v-on:keyup.enter="focusStockMinimo()"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.minimum_stock"
                        label="Stock Mínimo"
                        outlined
                        dense
                        ref="inimum_stock"
                        :error-messages="errors.get('minimum_stock')"
                        @keydown="errors.clear('minimum_stock')"
                        v-on:keyup.enter="focusCantidadCaja()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.box_quantity"
                        label="Cantidad X Caja"
                        ref="cantidadCaja"
                        outlined
                        dense
                        v-on:keyup.enter="focusCantidadBlister()"
                        :error-messages="errors.get('box_quantity')"
                        @keydown="errors.clear('box_quantity')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.blister_quantity"
                        label="Cantidad X Blister"
                        ref="cantidadBlister"
                        outlined
                        dense
                        v-on:keyup.enter="focusCantidadPresentacion()"
                        :error-messages="errors.get('blister_quantity')"
                        @keydown="errors.clear('blister_quantity')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.presentation_sale"
                        label="Cantidad X Presentación"
                        ref="cantidadPresentacion"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioCaja()"
                        :error-messages="errors.get('presentation_sale')"
                        @keydown="errors.clear('presentation_sale')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.buy_unit"
                        label="PC. Unidad"
                        outlined
                        dense

                        @keypress.enter="changeBuyUnit()"
                        :error-messages="errors.get('buy_unit')"
                        @keydown="errors.clear('buy_unit')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.buy_blister"
                        label="PC. Blister"
                        outlined
                        dense
                        @keypress.enter="changeBuyBlister()"
                        :error-messages="errors.get('buy_blister')"
                        @keydown="errors.clear('buy_blister')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.buy_box"
                        label="PC. Caja"
                        ref="pc_caja"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaUnidad()"
                        @keypress.enter="changeBuyBox()"
                        :error-messages="errors.get('buy_box')"
                        @keydown="errors.clear('buy_box')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.sale_unit"
                        label="PV. Unidad"
                        ref="pv_unidad"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaBlister()"
                        @keypress.enter="changeSaleUnit()"
                        :error-messages="errors.get('sale_unit')"
                        @keydown="errors.clear('sale_unit')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.sale_blister"
                        label="PV. Blister"
                        ref="pv_blister"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaCaja()"
                        @keypress.enter="changeSaleBlister()"
                        :error-messages="errors.get('sale_blister')"
                        @keydown="errors.clear('sale_blister')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.sale_box"
                        label="PV. Caja"
                        ref="pv_caja"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaMUnidad()"
                        @keypress.enter="changeSaleBox()"
                        :error-messages="errors.get('sale_box')"
                        @keydown="errors.clear('sale_box')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.minimum_sale_unit"
                        label="PMV. Unidad"
                        ref="pmv_unidad"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaMBlister()"
                        @keypress.enter="changeMinimumSaleUnit()"
                        :error-messages="errors.get('minimum_sale_unit')"
                        @keydown="errors.clear('minimum_sale_unit')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.minimum_sale_blister"
                        label="PMV. Blister"
                        ref="pmv_blister"
                        outlined
                        dense
                        v-on:keyup.enter="focusPrecioVentaMCaja()"
                        @keypress.enter="changeMinimumSaleBlister()"
                        :error-messages="errors.get('minimum_sale_blister')"
                        @keydown="errors.clear('minimum_sale_blister')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.minimum_sale_box"
                        label="PMV. Caja"
                        ref="pmv_caja"
                        outlined
                        dense
                        @keypress.enter="changeMinimumSaleBox()"
                        :error-messages="errors.get('minimum_sale_box')"
                        @keydown="errors.clear('minimum_sale_box')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.control_expiration"
                        label="Control por vencimiento"
                        color="success"
                        value="success"
                        hide-details
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.control_stock"
                        label="Control stock mínimo"
                        color="success"
                        value="success"
                        hide-details
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.control_refrigeration"
                        label="Requiere refrigeración"
                        color="success"
                        value="success"
                        hide-details
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.control_prescription"
                        label="Requiere receta"
                        color="success"
                        value="success"
                        hide-details
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="success darken-1"
                  text
                  @click="save"
                  ref="btn_guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="products"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
        :server-items-length="totalProducts"
        :options.sync="options"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-if="progress" v-slot:no-data>
          <div class="text-center">
            <v-progress-circular
              :size="40"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
        >
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <v-dialog
        v-model="dialogLaboratory"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="success" dark>NUEVO LABORATORIO</v-toolbar><br><br>
          <v-card-text>
            <v-text-field outlined dense label="Nombre" v-uppercase v-model="laboratory"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="success"
              @click="saveLaboratory"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogGeneric"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="success" dark>NUEVO GENÉRICO</v-toolbar><br><br>
          <v-card-text>
            <v-text-field outlined dense label="Nombre" v-uppercase v-model="generic"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="success"
              @click="saveGeneric"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogCategory"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="success" dark>NUEVA CATEGORÍA</v-toolbar><br><br>
          <v-card-text>
            <v-text-field outlined dense label="Nombre" v-uppercase v-model="category"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="success"
              @click="saveCategory"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogPresentation"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="success" dark>NUEVA PRESENTACIÓN</v-toolbar><br><br>
          <v-card-text>
            <v-text-field outlined dense label="Nombre" v-uppercase v-model="presentation"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="success"
              @click="savePresentation"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogLocation"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="success" dark>UBICACIÓN</v-toolbar><br><br>
          <v-card-text>
            <v-text-field outlined dense label="Nombre" v-uppercase v-model="location"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="success"
              @click="saveLocation"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import { mapState} from "vuex";
import {Errors} from "../../plugins/errors";
import axios from "axios";
import debounce from "lodash/debounce";
import SelectLab from "../../components/inputs/SelectLab";
import SelectGeneric from "../../components/inputs/SelectGeneric";
import SelectCategory from "../../components/inputs/SelectCategory";
import SelectPresentation from "../../components/inputs/SelectPresentation";
import SelectLocation from "../../components/inputs/SelectLocation";
import Autocomplete from "../../components/forms/Autocomplete";
import PDF from 'jspdf'
import 'jspdf-autotable';

export default {
  name: "Product",
  components: {Autocomplete, SelectLocation, SelectLab, SelectGeneric, SelectCategory, SelectPresentation},
  data() {
    return {
      dialog: false,
      dialogLaboratory: false,
      dialogGeneric: false,
      dialogCategory: false,
      dialogPresentation: false,
      dialogLocation: false,
      dialogDelete: false,
      progress: false,
      nextName: '',
      searchName: '',
      products: [],
      laboratories: [],
      generics: [],
      categories: [],
      presentations: [],
      locations: [],
      errors: new Errors(),
      lab_errors: new Errors(),
      generic_errors: new Errors(),
      category_errors: new Errors(),
      presentation_errors: new Errors(),
      location_errors: new Errors(),
      snackbar: false,
      laboratory: '',
      generic: '',
      category: '',
      presentation: '',
      location: '',
      filters: {
        search: ''
      },
      text: 'Operación realizada con éxito !!!',
      totalProducts: 0,
      options: {},
      headers: [
        { text: 'NOMBRE',             value: 'name',              sortable: false, width: "250px", class: "success white--text"},
        { text: 'STOCK',              value: 'stock.stock',       sortable: false, width: "150px", class: "success white--text"},
        { text: 'LABORATORIO',        value: 'laboratory.name',   sortable: false, width: "150px", class: "success white--text"},
        { text: 'PRINCIPIO ACTIVO',   value: 'generic.name',      sortable: false, width: "150px", class: "success white--text"},
        { text: 'ACCIÓN TERAPEÚTICA', value: 'category.name',     sortable: false, width: "150px", class: "success white--text"},
        { text: 'PRESENTACIÓN',       value: 'presentation.name', sortable: false, width: "50px", class: "success white--text"},
        { text: 'PRECIO COMPRA',      value: 'buy_unit',          sortable: false, width: "50px", class: "success white--text"},
        { text: 'PRECIO VENTA',       value: 'sale_unit',         sortable: false, width: "50px", class: "success white--text"},
        { text: 'ACCIONES',           value: 'actions',           sortable: false, width: "50px", class: "success white--text"},

      ],
      descriptionLimit: 60,
      productNames: [],
      isLoading: false,
      model: null,
      editedIndex: -1,
      editedItem: {
        id: null,
        barcode: '',
        name: '',
        laboratory: {
          id: null,
          name: ''
        },
        generic: {
          id: null,
          name: ''
        },
        category: {
          id: null,
          name: ''
        },
        presentation: {
          id: null,
          name: ''
        },
        location: {
          id: null,
          name: ''
        },
        maximum_stock: 100,
        minimum_stock: 5,
        box_quantity: 100,
        blister_quantity: 10,
        presentation_sale: 1,
        buy_unit: 0,
        buy_blister: 0,
        buy_box: 0,
        sale_unit: 0,
        sale_blister: 0,
        sale_box: 0,
        minimum_sale_unit: 0,
        minimum_sale_blister: 0,
        minimum_sale_box: 0,
        control_expiration: 1,
        control_stock: 1,
        control_refrigeration: 0,
        control_prescription: 0,
      },
      defaultItem: {
        id: null,
        barcode: '',
        name: '',
        laboratory: {
          id: null,
          name: ''
        },
        generic: {
          id: null,
          name: ''
        },
        category: {
          id: null,
          name: ''
        },
        presentation: {
          id: null,
          name: ''
        },
        location: {
          id: null,
          name: ''
        },
        maximum_stock: 100,
        minimum_stock: 5,
        box_quantity: 100,
        blister_quantity: 10,
        presentation_sale: 1,
        buy_unit: 0,
        buy_blister: 0,
        buy_box: 0,
        sale_unit: 0,
        sale_blister: 0,
        sale_box: 0,
        minimum_sale_unit: 0,
        minimum_sale_blister: 0,
        minimum_sale_box: 0,
        control_expiration: 1,
        control_stock: 1,
        control_refrigeration: 0,
        control_prescription: 0,
      },
      parentLaboratory: {
        id: null,
        name: ''
      },
      parentGeneric: {
        id: null,
        name: ''
      },
      parentCategory: {
        id: null,
        name: ''
      },
      parentPresentation: {
        id: null,
        name: ''
      },
      parentLocation: {
        id: null,
        name: ''
      },
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'REGISTRAR PRODUCTO' : 'EDITAR PRODUCTO'
    },
    search: {
      get () {
        return this.filters.search;
      },
      set (value) {
        this.filters.search = value;
        this.filterSearch()
      }
    },
    ...mapState('auth', ['user'])
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      async handler () {
        await this.fetchProducts();
      }
    },
    searchName (val) {
      if (this.productNames.length > 0) return

      axios.get(`/api/products/name/${val}`)
        .then(response => this.productNames = response.data.products)
        .catch(error => console.log(error))
    },
  },
  created () {
    this.fetchProducts()
    this.listLaboratories()
    this.listCategories()
    this.listGenerics()
    this.listPresentations()
    this.listLocations()
  },
  methods: {
    getReportInventario (id) {
      setTimeout(() => {
        axios.get(`api/report/productStock/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDF()
            })
            .catch(error => console.log(error))
      }, 1000)
    },
    headRows() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        stock: 'STOCK',
        lot: 'LOTE',
        expiration_date: 'VENCIMIENTO',
      }];
    },
    bodyRows(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          stock: pdfBody[i].stock,
          lot: pdfBody[i].lot,
          expiration_date: pdfBody[i].expiration_date,
        });
      }
      return body;
    },
    createPDF () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'CONTROL DE INVENTARIO')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRows(),
        body: this.bodyRows(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 220,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 75, halign: 'left'},
          2: {cellWidth: 80, halign: 'left'},
          3: {cellWidth: 40, valign: 'middle', halign: 'center'},
          4: {cellWidth: 50, valign: 'middle', halign: 'right'},
          5: {cellWidth: 60, halign: 'right'},
        }
      });

      doc.save(`Inventario${Date.now()}.pdf`);
    },
    fetchProducts () {
      const params = { ...this.options, ...this.filters }
      let modifyList = []
      let quotient = 0
      let rest     = 0
      this.progress = true
      axios.get('/api/products', {params})
        .then(response => {
          modifyList = response.data.data.data
          modifyList.filter((value) => {
            return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
          })
          modifyList.filter((value) => {
            return value.generic == null ? value.generic = this.parentGeneric : value.generic
          })
          modifyList.filter((value) => {
            return value.category == null ? value.category = this.parentCategory : value.category
          })
          modifyList.filter((value) => {
            return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
          })
          modifyList.filter((value) => {
            return value.location == null ? value.location = this.parentLocation : value.location
          })

          modifyList.filter((value) => {
            if (value.stock !== null) {
              if (value.box_quantity > 1) {
                quotient = parseInt(value.stock.stock) % parseInt(value.box_quantity)
                rest  = (parseInt(value.stock.stock) - parseInt(quotient)) / parseInt(value.box_quantity)
                value.stock.stock = rest + 'F' + quotient
              }else {
                value.stock.stock = parseInt(value.stock.stock)
              }

              return value.stock
            }
          })

          this.products = response.data.data.data
          this.totalProducts = response.data.data.total
          this.productNames = response.data.products
          this.progress = false
        })
        .catch(error => console.log(error))
    },

    saveProducts () {
      const createProduct = {
        'barcode'              : this.editedItem.barcode,
        'name'                 : this.editedItem.name,
        'lab_mark_id'          : this.editedItem.laboratory.id,
        'active_principle_id'  : this.editedItem.generic.id,
        'therapeutic_action_id': this.editedItem.category.id,
        'presentation_id'      : this.editedItem.presentation.id,
        'location_id'          : this.editedItem.location.id,
        'maximum_stock'        : this.editedItem.maximum_stock,
        'minimum_stock'        : this.editedItem.minimum_stock,
        'box_quantity'         : this.editedItem.box_quantity,
        'blister_quantity'     : this.editedItem.blister_quantity,
        'presentation_sale'    : this.editedItem.presentation_sale,
        'buy_unit'             : this.editedItem.buy_unit,
        'buy_blister'          : this.editedItem.buy_blister,
        'buy_box'              : this.editedItem.buy_box,
        'sale_unit'            : this.editedItem.sale_unit,
        'sale_blister'         : this.editedItem.sale_blister,
        'sale_box'             : this.editedItem.sale_box,
        'minimum_sale_unit'    : this.editedItem.minimum_sale_unit,
        'minimum_sale_blister' : this.editedItem.minimum_sale_blister,
        'minimum_sale_box'     : this.editedItem.minimum_sale_box,
        'control_expiration'   : this.editedItem.control_expiration,
        'control_stock'        : this.editedItem.control_stock,
        'control_refrigeration': this.editedItem.control_refrigeration,
        'control_prescription' : this.editedItem.control_prescription,
      }

      axios.post('/api/products', createProduct)
        .then(() => {
          this.fetchProducts()
          this.close()
          this.snackbar = true
          this.reset()
        },error => this.errors.record(error.response.data.errors));
    },

    updateProducts () {
      const updateProduct = {
        'barcode'              : this.editedItem.barcode,
        'name'                 : this.editedItem.name,
        'lab_mark_id'          : this.editedItem.laboratory.id,
        'active_principle_id'  : this.editedItem.generic.id,
        'therapeutic_action_id': this.editedItem.category.id,
        'presentation_id'      : this.editedItem.presentation.id,
        'location_id'          : this.editedItem.location.id,
        'maximum_stock'        : this.editedItem.maximum_stock,
        'minimum_stock'        : this.editedItem.minimum_stock,
        'box_quantity'         : this.editedItem.box_quantity,
        'blister_quantity'     : this.editedItem.blister_quantity,
        'presentation_sale'    : this.editedItem.presentation_sale,
        'buy_unit'             : this.editedItem.buy_unit,
        'buy_blister'          : this.editedItem.buy_blister,
        'buy_box'              : this.editedItem.buy_box,
        'sale_unit'            : this.editedItem.sale_unit,
        'sale_blister'         : this.editedItem.sale_blister,
        'sale_box'             : this.editedItem.sale_box,
        'minimum_sale_unit'    : this.editedItem.minimum_sale_unit,
        'minimum_sale_blister' : this.editedItem.minimum_sale_blister,
        'minimum_sale_box'     : this.editedItem.minimum_sale_box,
        'control_expiration'   : this.editedItem.control_expiration,
        'control_stock'        : this.editedItem.control_stock,
        'control_refrigeration': this.editedItem.control_refrigeration,
        'control_prescription' : this.editedItem.control_prescription,
      }

      axios.put(`/api/products/${this.editedItem.id}`, updateProduct)
        .then(() => {
          this.fetchProducts()
          this.close()
          this.snackbar = true;
          this.reset()
        },error => this.errors.record(error.response.data.errors));
    },

    filterSearch: debounce(async function () {
      await this.fetchProducts();
    }),

    save () {
      if (this.editedIndex > -1) {
        this.updateProducts()
      } else {
        this.saveProducts()
      }
      this.errors.record('');
    },

    deleteProducts () {
      axios.delete(`/api/products/${this.editedItem.id}`)
        .then(() => {
          this.fetchProducts()
          this.close()
          this.snackbar = true;
        })
    },

    saveLaboratory () {
      const createLaboratory = {
        'name': this.laboratory
      }
      axios.post('/api/products/laboratory', createLaboratory)
          .then(() => {
            this.listLaboratories()
            this.closeLaboratory()
          },error => this.lab_errors.record(error.response.data.errors));
    },

    saveGeneric () {
      const createGeneric = {
        'name': this.generic
      }
      axios.post('/api/products/generic', createGeneric)
        .then(() => {
          this.listGenerics()
          this.closeGeneric()
        },error => this.generic_errors.record(error.response.data.errors));
    },

    saveCategory () {
      const createCategory = {
        'name': this.category
      }
      axios.post('/api/products/category', createCategory)
        .then(() => {
          this.listCategories()
          this.closeCategory()
        },error => this.category_errors.record(error.response.data.errors));
    },

    savePresentation () {
      const createPresentation = {
        'name': this.presentation
      }
      axios.post('/api/products/presentation', createPresentation)
        .then(() => {
          this.listPresentations()
          this.closePresentation()
        },error => this.presentation_errors.record(error.response.data.errors));
    },

    saveLocation () {
      const createLocation = {
        'name': this.location
      }
      axios.post('/api/products/location', createLocation)
        .then(() => {
          this.listLocations()
          this.closeLocation()
        },error => this.location_errors.record(error.response.data.errors));
    },

    listLaboratories () {
      axios.get('/api/products/list/laboratories')
        .then(response => {
          if (Object.entries(response.data.laboratories).length === 0) {
            this.laboratories = this.parentModify
          } else {
            this.laboratories = response.data.laboratories
          }
      })
    },

    listGenerics () {
      axios.get('/api/products/list/generics')
        .then(response => {
          if (Object.entries(response.data.generics).length === 0) {
            this.generics = this.parentModify
          } else {
            this.generics = response.data.generics
          }
        })
    },

    listCategories () {
      axios.get('/api/products/list/categories')
        .then(response => {
          if (Object.entries(response.data.categories).length === 0) {
            this.categories = this.parentModify
          } else {
            this.categories = response.data.categories
          }
        })
    },

    listPresentations () {
      axios.get('/api/products/list/presentations')
        .then(response => {
          if (Object.entries(response.data.presentations).length === 0) {
            this.presentations = this.parentModify
          } else {
            this.presentations = response.data.presentations
          }
        })
    },

    listLocations () {
      axios.get('/api/products/list/locations')
        .then(response => {
          if (Object.entries(response.data.locations).length === 0) {
            this.locations = this.parentModify
          } else {
            this.locations = response.data.locations
          }
        })
    },

    editItem (item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteProducts()
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
      this.editedIndex = -1
      this.errors.record('')
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeLaboratory () {
      this.dialogLaboratory = false
      this.laboratory = ''
    },
    closeGeneric () {
      this.dialogGeneric = false
      this.generic = ''
    },
    closeCategory () {
      this.dialogCategory = false
      this.category = ''
    },
    closePresentation () {
      this.dialogPresentation = false
      this.presentation = ''
    },
    closeLocation () {
      this.dialogLocation = false
      this.location = ''
    },
    focusNext(event) {
      console.log(event)
      const next = event.target.nextElementSibling
      if(next === null) return
      event.target.nextElementSibling.focus()
    },
    changeBuyUnit () {
      this.editedItem.buy_blister = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(4)
      this.editedItem.buy_box     = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
    },
    changeBuyBox () {
      this.editedItem.buy_unit    = parseFloat(parseFloat(this.editedItem.buy_box) / parseFloat(this.editedItem.box_quantity)).toFixed(4)
      this.editedItem.buy_blister = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(4)
      this.editedItem.buy_box     = parseFloat(this.editedItem.buy_box).toFixed(2)
    },
    changeSaleUnit () {
      this.editedItem.sale_blister         = (parseFloat(this.editedItem.sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
      this.editedItem.sale_box             = (parseFloat(this.editedItem.sale_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
      this.editedItem.minimum_sale_unit    = parseFloat(this.editedItem.sale_unit).toFixed(2)
      this.editedItem.minimum_sale_blister = parseFloat(this.editedItem.sale_blister).toFixed(2)
      this.editedItem.minimum_sale_box     = parseFloat(this.editedItem.sale_box).toFixed(2)
      this.editedItem.sale_unit            = parseFloat(this.editedItem.sale_unit).toFixed(2)
    },
    changeSaleBox () {
      this.editedItem.minimum_sale_box = this.editedItem.sale_box
    },
    changeMinimumSaleUnit () {
      this.editedItem.minimum_sale_blister = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
      this.editedItem.minimum_sale_box     = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
    },
    changeMinimumSaleBox () {
      //this.editedItem.minimum_sale_unit    = (parseFloat(this.editedItem.minimum_sale_box) / parseFloat(this.editedItem.box_quantity)).toFixed(2)
      //this.editedItem.minimum_sale_blister = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
    },
    changeBuyBlister () {
      this.editedItem.buy_box = (this.editedItem.buy_blister * parseInt(this.editedItem.box_quantity / this.editedItem.blister_quantity)).toFixed(2)
    },
    changeSaleBlister () {
      this.editedItem.sale_box = ((parseFloat(this.editedItem.sale_blister) / parseFloat(this.editedItem.blister_quantity)) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
      this.editedItem.minimum_sale_blister = parseFloat(this.editedItem.sale_blister).toFixed(2)
      this.editedItem.minimum_sale_box     = parseFloat(this.editedItem.sale_box).toFixed(2)
      this.editedItem.sale_blister         = parseFloat(this.editedItem.sale_blister).toFixed(2)
    },
    changeMinimumSaleBlister () {
      this.editedItem.minimum_sale_box = (this.editedItem.minimum_sale_blister * parseInt(this.editedItem.box_quantity / this.editedItem.blister_quantity)).toFixed(2)
    },
    focusName () {
      this.$refs.nombre_nuevo.$refs.nombre.focus()
    },
    focusStockMaximo () {
      this.$refs.aximum_stock.focus()
    },
    focusStockMinimo () {
      this.$refs.inimum_stock.focus()
    },
    focusCantidadCaja () {
       this.$refs.cantidadCaja.focus()
    },
    focusCantidadBlister () {
      this.$refs.cantidadBlister.focus()
    },
    focusCantidadPresentacion () {
      this.$refs.cantidadPresentacion.focus()
    },
    focusPrecioCaja () {
      this.$refs.pc_caja.focus()
    },
    focusPrecioVentaUnidad () {
      this.$refs.pv_unidad.focus()
    },
    focusPrecioVentaBlister () {
      this.$refs.pv_blister.focus()
    },
    focusPrecioVentaCaja () {
      this.$refs.pv_caja.focus()
    },
    focusPrecioVentaMUnidad () {
      this.$refs.pmv_unidad.focus()
    },
    focusPrecioVentaMBlister () {
      this.$refs.pmv_blister.focus()
    },
    focusPrecioVentaMCaja () {
      this.$refs.pmv_caja.focus()
    },
    focusBotonGuardar () {
      this.$refs.btn_guardar.focus()
    },
  },

}
</script>
<style>
  .inputs-container,
  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .theme--light.v-input {
    padding-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
   .v-progress-circular {
     margin: 1rem;
   }
</style>
